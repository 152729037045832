import { template as template_74fd0a2ec86e4ab0804c9a651d80fa98 } from "@ember/template-compiler";
const FKText = template_74fd0a2ec86e4ab0804c9a651d80fa98(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
